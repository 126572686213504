import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >
<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2995 1125 c21 -8 41 -12 43 -10 8 8 107 -54 110 -69 5 -23 -15 -20
-53 10 -39 29 -121 54 -181 54 -55 0 -143 -29 -174 -57 -14 -13 -29 -22 -35
-20 -15 5 -12 30 4 35 9 3 11 0 6 -8 -5 -9 -4 -11 4 -6 6 4 9 11 6 16 -3 5 12
18 35 30 22 11 40 19 40 16 0 -2 17 2 38 10 48 17 107 17 157 -1z m800 -41
c20 -20 26 -34 23 -55 -3 -21 0 -29 11 -29 9 -1 -4 -8 -29 -17 -57 -19 -80
-40 -80 -70 0 -29 -23 -30 -50 -1 -22 24 -25 39 -9 55 7 7 4 21 -11 47 -18 29
-28 36 -53 36 l-32 1 29 16 c35 19 111 40 148 42 17 1 37 -9 53 -25z m-268
-30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-503 -45 c27 -13
48 -28 47 -34 -3 -13 -32 -7 -51 10 -24 21 -114 29 -165 15 -25 -7 -45 -16
-45 -20 0 -11 -39 -20 -45 -10 -7 11 18 29 72 51 60 24 121 20 187 -12z m-387
-61 c38 -39 89 -146 98 -207 7 -43 7 -44 -16 -38 -13 4 -73 7 -134 8 -60 0
-99 4 -85 8 18 5 21 9 11 15 -11 7 -11 10 0 17 17 10 -9 34 -120 109 -83 56
-80 62 11 25 34 -14 63 -23 66 -20 3 2 13 0 23 -5 23 -13 63 4 78 34 9 16 8
31 -4 65 -8 24 -15 45 -15 46 0 10 60 -29 87 -57z m896 34 c29 -30 37 -53 12
-32 -12 10 -16 9 -20 -2 -3 -8 -5 -20 -3 -26 3 -11 -9 -13 -31 -4 -10 4 -8 9
8 21 19 15 20 18 7 36 -13 17 -18 18 -35 7 -17 -10 -21 -10 -21 2 0 15 14 24
41 25 8 1 27 -12 42 -27z m-173 3 c-7 -8 -22 -15 -33 -15 -25 0 -12 16 18 23
11 3 22 5 24 6 2 0 -2 -6 -9 -14z m811 4 c11 -5 17 -15 13 -21 -7 -11 13 -28
34 -28 6 0 12 7 12 15 0 28 22 15 93 -59 112 -114 172 -228 212 -396 21 -91
19 -145 -5 -178 -20 -27 -19 -69 1 -56 5 3 9 -3 9 -13 0 -17 -17 -56 -72 -166
-10 -21 -23 -61 -29 -87 -7 -31 -21 -59 -37 -74 -15 -14 -43 -42 -62 -62 -69
-72 -164 -163 -168 -160 -4 4 12 43 60 146 39 82 48 134 39 215 -1 11 0 36 1
55 2 29 0 35 -16 35 -11 0 -21 6 -24 13 -5 15 -38 16 -47 1 -4 -6 -13 -8 -20
-5 -7 2 -22 -2 -33 -10 -27 -18 -43 -11 -81 36 -29 36 -31 44 -31 117 0 73 3
84 47 171 29 55 53 92 63 92 8 0 35 7 59 16 44 15 60 10 61 -21 0 -11 72 -55
89 -55 6 0 11 6 11 13 0 9 15 13 48 13 46 -1 47 0 50 30 3 28 0 32 -29 38 -18
3 -38 15 -46 26 -17 24 -33 26 -33 5 0 -27 -18 -16 -25 15 -4 17 -12 30 -19
30 -7 0 -21 9 -31 20 -18 20 -45 27 -45 12 0 -4 -12 -13 -27 -19 -26 -12 -53
-52 -53 -79 0 -19 -40 -28 -55 -13 -7 8 -16 29 -20 48 -7 33 -6 34 20 29 36
-7 44 16 15 48 -17 17 -19 24 -9 24 8 0 23 13 33 29 12 17 27 27 40 26 37 -3
73 24 73 56 0 16 -4 30 -9 30 -4 1 -11 3 -15 4 -5 2 -18 6 -30 10 -13 4 -23
17 -25 31 -2 14 -7 24 -13 24 -12 -1 -9 -51 3 -59 14 -9 34 -52 28 -59 -3 -3
-16 4 -29 15 -12 11 -31 23 -42 27 -18 5 -21 15 -22 61 l-1 55 34 0 c20 0 44
-5 55 -11z m-756 -39 c3 -5 1 -10 -4 -10 -8 0 -85 -53 -109 -75 -7 -7 10 -45
19 -45 17 0 39 -27 39 -49 0 -11 4 -22 8 -25 11 -7 62 70 62 93 0 18 31 61 44
61 3 0 6 -6 6 -13 0 -7 7 -22 15 -33 12 -16 16 -17 24 -4 8 12 11 11 17 -5 4
-11 13 -27 21 -36 15 -17 8 -48 -11 -50 -6 -1 -25 0 -41 1 -20 1 -38 -6 -50
-20 l-19 -21 27 6 c23 6 27 4 27 -14 0 -12 -6 -21 -13 -21 -20 0 -52 -31 -76
-73 l-21 -37 63 -1 62 -2 -75 -11 c-58 -8 -76 -15 -78 -28 -2 -10 -10 -18 -18
-18 -31 0 -3 -17 38 -24 101 -16 361 -74 335 -75 -16 -1 -47 3 -70 8 -23 6
-102 18 -175 27 -111 14 -135 15 -142 4 -7 -11 -11 -10 -24 2 -23 24 -110 33
-136 15 -20 -14 -19 -16 52 -80 127 -113 133 -119 118 -111 -8 4 -35 21 -59
36 -25 15 -51 28 -58 28 -23 0 -14 -30 12 -40 17 -6 25 -17 24 -32 l0 -23 -9
23 c-5 12 -15 23 -22 23 -16 2 -31 5 -38 9 -3 1 -21 4 -40 5 -19 1 -44 6 -54
10 -16 7 -18 5 -13 -9 4 -10 3 -15 -3 -11 -13 8 -13 -11 1 -36 19 -36 5 -39
-33 -6 -38 33 -38 33 -38 109 0 81 13 108 51 108 24 0 24 4 -2 40 -17 23 -20
36 -14 67 12 64 104 218 183 304 43 46 52 51 95 54 27 2 51 6 54 9 10 10 41 7
48 -4z m485 -10 c0 -5 -10 -10 -22 -10 -19 0 -20 2 -8 10 19 13 30 13 30 0z
m-911 -22 c40 -15 11 -29 -32 -16 -28 8 -46 8 -74 0 -24 -8 -41 -8 -47 -2 -6
6 -8 10 -5 10 2 0 22 7 44 14 39 14 64 13 114 -6z m1041 -58 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-1082 -29 c18 -11
12 -41 -10 -55 -13 -8 -23 -8 -35 0 -36 22 -24 64 17 64 8 0 21 -4 28 -9z
m1072 -17 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
-13 10 -21z m54 0 c20 -19 21 -34 2 -34 -7 0 -16 11 -18 25 -6 29 -5 30 16 9z
m-476 -88 c3 -15 1 -16 -17 -6 -12 6 -19 16 -15 21 9 15 28 5 32 -15z m-78
-40 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m754 -35 c-3 -3
-15 6 -27 18 l-22 24 27 -19 c14 -10 24 -21 22 -23z m-2061 -92 c26 -5 85 -9
131 -9 105 0 236 -11 236 -20 0 -3 -100 -19 -222 -33 -221 -27 -249 -32 -403
-62 -83 -16 -344 -62 -405 -71 -19 -3 -44 -9 -55 -13 -11 -5 -74 -21 -140 -36
-240 -56 -423 -126 -522 -202 l-46 -35 6 54 c9 73 32 117 72 134 44 18 319 91
434 115 51 10 96 21 100 24 13 7 63 15 171 26 58 5 127 16 155 24 27 7 152 26
278 41 125 15 227 30 227 34 0 7 -132 20 -207 20 -61 0 -53 18 10 22 59 4 111
1 180 -13z m1112 -149 c4 -7 -3 -8 -22 -4 -38 9 -42 14 -10 14 14 0 29 -5 32
-10z m495 0 c17 -6 9 -7 -25 -3 -27 3 -52 7 -54 9 -8 7 54 2 79 -6z m-1263
-25 c58 -17 133 -68 140 -96 3 -10 9 -19 14 -19 5 0 9 -8 9 -17 0 -11 -3 -14
-8 -7 -4 6 -16 13 -28 16 -16 4 -27 -4 -50 -38 -37 -55 -47 -56 -29 -3 8 23
15 54 15 70 0 27 -3 29 -37 29 -21 0 -69 -9 -108 -20 -38 -11 -78 -20 -87 -20
-24 0 -13 24 18 40 13 7 24 19 24 27 0 7 4 13 9 13 5 0 13 9 16 20 8 24 33 25
102 5z m830 -1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m26
-137 c10 -10 22 -15 27 -12 6 4 24 -4 41 -15 16 -12 33 -20 36 -16 7 7 63 -25
63 -36 0 -4 5 -8 12 -8 20 0 48 -32 48 -57 0 -19 4 -23 24 -19 15 3 27 -1 30
-10 3 -8 18 -14 33 -14 15 0 43 -9 61 -20 38 -23 44 -66 12 -95 -11 -10 -22
-34 -25 -54 -12 -74 -14 -78 -54 -96 -33 -14 -40 -23 -43 -49 -2 -21 -10 -34
-25 -41 -14 -6 -21 -16 -18 -25 3 -9 -8 -25 -25 -39 -17 -13 -30 -29 -30 -37
0 -23 -15 -16 -41 17 -23 31 -68 164 -69 207 0 13 -19 43 -45 70 -25 26 -45
52 -45 58 0 7 -6 14 -12 17 -7 3 -24 26 -37 52 -22 42 -23 50 -11 78 7 18 14
50 14 72 1 22 5 43 9 46 4 3 9 15 9 25 2 25 35 26 61 1z m-664 -116 c-1 -32
-2 -33 -8 -11 -4 14 -11 45 -14 70 l-7 45 15 -34 c8 -18 15 -50 14 -70z m996
-871 c41 -19 65 -44 65 -69 0 -17 -40 -13 -47 4 -8 23 -57 45 -97 45 -26 0
-40 -7 -60 -31 -14 -17 -26 -36 -26 -43 0 -20 39 -55 68 -62 163 -37 202 -79
161 -172 -42 -96 -267 -74 -269 26 0 32 21 27 53 -13 26 -33 32 -35 85 -35 63
1 86 13 102 54 9 24 7 31 -19 57 -16 16 -38 29 -49 29 -11 0 -43 8 -71 17 -92
29 -127 102 -77 159 24 29 74 52 114 53 13 1 43 -8 67 -19z m572 -17 c2 -114
3 -123 17 -123 7 0 19 7 26 15 18 22 77 29 119 15 79 -26 117 -141 71 -215
-29 -47 -62 -65 -118 -65 -40 0 -54 5 -76 27 -23 22 -29 25 -37 13 -5 -8 -9
-21 -9 -27 0 -7 -9 -13 -20 -13 -19 0 -20 7 -20 205 l0 205 23 0 c20 0 23 -5
24 -37z m-163 -89 c14 -5 16 -28 16 -145 0 -129 -1 -139 -19 -139 -10 0 -21 9
-24 20 -5 19 -6 19 -60 -6 -52 -23 -58 -24 -91 -10 -51 21 -69 55 -74 146 -6
97 2 130 28 130 19 0 20 -7 20 -98 0 -70 4 -104 15 -119 17 -25 73 -37 108
-24 44 17 50 38 56 209 1 45 1 45 25 36z"/>
<path d="M4449 2978 c46 -149 54 -169 64 -159 6 6 6 24 -2 53 -7 24 -15 54
-18 67 -6 25 -32 61 -44 61 -4 0 -4 -10 0 -22z"/>
<path d="M3130 2879 c0 -8 7 -23 16 -34 13 -17 16 -18 24 -5 12 19 13 40 1 40
-5 0 -16 3 -25 6 -10 4 -16 1 -16 -7z"/>
<path d="M4394 1621 c-42 -11 -52 -18 -70 -53 -11 -20 -14 -45 -11 -71 5 -40
40 -87 64 -87 7 0 13 -4 13 -9 0 -5 18 -5 43 0 45 10 83 44 93 85 11 42 -13
103 -49 124 -34 21 -36 22 -83 11z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
